import React, { useCallback } from "react";
import {
    Backdrop,
    Button,
    Card,
    CardContent,
    CardActions,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    Checkbox,
    FormControlLabel,
    Grid2 as Grid,
    CardHeader,
    IconButton,
    Icon,
    Box
} from "@mui/material";
import BusinessIcon from '@mui/icons-material/Business';
import { Formik, Form, Field, ErrorMessage } from "formik";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Yup from "yup";
import CloseIcon from '@mui/icons-material/Close';
import { phone } from 'phone';
import { debounce, debouncePromise } from "../../../../_helpers/debounce";
import OTPInput from "../../../_shared/elements/otp/OTP";
import { generalService } from "../../../../_services/general.services";

import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PhoneNumberField from "../../../Formik/CustomFields/phoneNumberOTP";
import { LoadingButton } from "@mui/lab";

function SpinningIcon() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <HourglassTopIcon
                color="warning"
                sx={{
                    animation: 'spin 2s linear infinite',  // 2s duration, continuous spin
                    '@keyframes spin': {
                        '0%': { transform: 'rotate(0deg)' },
                        '100%': { transform: 'rotate(360deg)' }
                    }
                }}
            />
        </Box>
    );
}

const RegisterCompany = () => {

    const [open, setOpen] = React.useState(false);
    const [isAccordionExpanded, setIsAccordionExpanded] = React.useState(false);
    const [domainData, setDomainData] = React.useState({});
    const [countdown, setCountdown] = React.useState(0); // Time remaining for OTP
    const [validatingDomain, setValidatingDomain] = React.useState(false);
    const [addressValidation, setAddressValidation] = React.useState({});

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const navigate = useNavigate();

    const emailValidation = Yup.string()
        .required()
        .email("Invalid email format")
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    

    // Form validation schema
    const validationSchema = Yup.object({
        corporateEmail: Yup.string().required()
            .test("domain_validation", "Error validating your email.", async (value, testContext) => {
                console.log("Valid Email", await emailValidation.isValid(value), value)
                if(await emailValidation.isValid(value)){
                    const domain = value.split("@")[1] || "";
                    const noError = await debouncedValidation(domain);
                    return noError === true ? noError : testContext.createError({ message: noError });
                }else{
                    return testContext.createError({ message: "Your email is not valid." });
                }

            }),
        companyName: Yup.string()
            .required("Company name is required"),
        firstName: Yup.string()
            .required("First name is required"),
        lastName: Yup.string()
            .required("Last name is required"),
        password: Yup.string().required('Password is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character."
            ),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('The confirm password field is required'),
        phoneNumber: Yup.string()
            .required("Phone number is required"),
        address: Yup.string()
            .required("Address is required").test("address_validation", "Address error", async (value, testContext) => {

                const zipCode = testContext.parent.zipCode;
                const country = testContext.parent.country;

                const noError = await debouncedAddressValidation(value, zipCode, country);
                return noError === true ? noError : testContext.createError({ message: noError });

            }),
        zipCode: Yup.string()
            .required("Zip Code is required"),
        enteredRecord: Yup.boolean()
            .oneOf([true, false], "You must go through this process")
            .required("Domain verification is required"),
        validDomain: Yup.boolean()
            .oneOf([true], "You must go through this process")
            .required("Domain verification is required"),
        validPhone: Yup.boolean()
            .oneOf([true], "You must go through this process")
            .required("Phone verification is required"),
        consentSMS: Yup.boolean()
            .oneOf([true], "You must agree to receive messages")
            .required("Consent is required"),
    });



    const validateDomain = async (domain) => {
        let noError = true;

        try {

            if (!domain) {
                throw "Please enter a valid Email Address with Domain";
            }

            const body = {
                domain: domain
            };

            const response = await generalService.validateDomain(body);
            const data = response.data;

            if (!data.isValid) {
                throw data.message;
            }

            setIsAccordionExpanded(true);
            setDomainData(data);

        } catch (error) {
            console.log("Validate Domain Error", error);
            noError = typeof error === "string" ? error : "Error validating domain";
        } finally {
            return noError;
        }

    };

    const debouncedValidation = React.useRef(debouncePromise(validateDomain, 1000)).current; // Debounce the API call

    // Simulated address validation function
    const validateAddress = async (address, zipCode, country) => {
        let noError = true;

        try {

            if (!address && !zipCode && !country) {
                throw "Invalid Address";
            }

            const response = await generalService.validateAddress({
                direccion: address,
                zipcode: zipCode,
            });

            console.log("Address Data", response);
            setAddressValidation(response.data);

        } catch (error) {
            console.log("Address Error", error);
            noError = typeof error === "string" ? error : "Error validating address";
        } finally {
            return noError;
        }
    };

    const debouncedAddressValidation = React.useRef(debouncePromise(validateAddress, 1000)).current; // Debounce the API call



    // Validate DNS record
    async function validateDNSRecord(data, setFieldValue, setIsAccordionExpanded, setFieldError) {
        const intervalTime = 20000; // 20 seconds
        const timeoutTime = 60000;  // 1 minute

        let isRecordValidated = false;

        // Set the interval to check every 20 seconds
        const intervalId = setInterval(async () => {
            try {
                const result = (await generalService.checkTXTRecord(data)).data;

                if (result?.isValid) {
                    clearInterval(intervalId); // Stop the interval once the record is validated
                    isRecordValidated = true;
                    setFieldValue("validDomain", true); // Mark domain as valid
                    setIsAccordionExpanded(false); // Contract the accordion
                    setFieldError("validDomain", undefined);
                    setFieldError("enteredRecord", undefined);
                    setValidatingDomain(false);
                }
            } catch (error) {
                console.error("Error while checking DNS record", error);
            }
        }, intervalTime);

        // Set a timeout for 1 minute
        setTimeout(() => {
            clearInterval(intervalId); // Stop checking after 1 minute
            if (!isRecordValidated) {
                setFieldValue("enteredRecord", false); // Uncheck the checkbox
                setFieldValue("validDomain", false); // Mark domain as invalid
                setFieldError("enteredRecord", "Validation timed out. Please check the DNS record and try again.");
                setValidatingDomain(false);
            }
        }, timeoutTime);
    }


    // Countdown effect
    React.useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setInterval(() => setCountdown((prev) => prev - 1), 1000);
        }
        return () => clearInterval(timer);
    }, [countdown]);

    return (
        <>
            <Button
                component="label"
                variant="contained"
                startIcon={<BusinessIcon />}
                onClick={handleOpen}
            >
                Register Company
            </Button>
            <Backdrop
                sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
                open={open}
            >
                <Card sx={{ width: { xs: "90vw", md: "30vw" }, height: "80vh", overflow: "auto" }} onClick={(e) => e.stopPropagation()}>
                    <CardHeader
                        action={
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        <Typography variant="h5" component="div" gutterBottom>
                            Create Corporate Account
                        </Typography>
                        <Formik
                            initialValues={{
                                corporateEmail: "",
                                firstName: "",
                                lastName: "",
                                phoneNumber: "",
                                address: "",
                                zipCode: "",
                                country: "",
                                enteredRecord: false,
                                validDomain: false,
                                validPhone: false,
                                consentSMS: false,
                                password: "",
                                confirmPassword: "",
                                companyName: ""
                            }}
                            validationSchema={validationSchema}
                            validateOnChange={false} // Disable auto-validation on change
                            validateOnBlur={false} // Disable auto-validation on blur
                            onSubmit={async (values, { setSubmitting }) => {
                                setSubmitting(true);
                                try {
                                    console.log(addressValidation);
                                    
                                    const payload = {
                                        ...values,
                                        parsedAddress: addressValidation.direccion,
                                        city: addressValidation.municipio,
                                        state: addressValidation.departamento,
                                        companyDomain: values.corporateEmail.split("@")[1],
                                    }
                                    const response = await generalService.createCompany(payload);

                                    setSubmitting(false);
                                    handleClose();
                                    navigate("/corporate/login")

                                } catch (error) {
                                    console.error(error);
                                    toast.error("Error registering your company.")
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({
                                isSubmitting,
                                isValid,
                                values,
                                dirty,
                                handleChange,
                                handleBlur,
                                errors,
                                touched,
                                setFieldValue,
                                setFieldError,
                                validateField,
                                setTouched
                            }) => (
                                <Form>
                                    <Grid container spacing={1}>
                                        <Grid size={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                disabled={values.validDomain}
                                                autoComplete="off"
                                                margin="normal"
                                                name="corporateEmail"
                                                label="Corporate Email"
                                                variant="outlined"
                                                onChange={async (e) => {
                                                    await setFieldValue("corporateEmail", e.target.value)
                                                    await validateField("corporateEmail");
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage name="corporateEmail" component="div" style={{ color: "red" }} />
                                        </Grid>

                                        <Grid size={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                autoComplete="off"
                                                margin="normal"
                                                name="companyName"
                                                label="Company Name"
                                                variant="outlined"
                                                onChange={async (e) => {
                                                    await setFieldValue("companyName", e.target.value)
                                                    await validateField("companyName");
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage name="companyName" component="div" style={{ color: "red" }} />

                                        </Grid>

                                        <Grid size={12}>

                                            <Field
                                                as={TextField}
                                                fullWidth
                                                autoComplete="off"
                                                margin="normal"
                                                name="firstName"
                                                label="First Name"
                                                variant="outlined"
                                                onChange={async (e) => {
                                                    await setFieldValue("firstName", e.target.value)
                                                    await validateField("firstName");
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage name="firstName" component="div" style={{ color: "red" }} />
                                        </Grid>

                                        <Grid size={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                autoComplete="off"
                                                margin="normal"
                                                name="lastName"
                                                label="Last Name"
                                                variant="outlined"
                                                onChange={async (e) => {
                                                    await setFieldValue("lastName", e.target.value)
                                                    await validateField("lastName");
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage name="lastName" component="div" style={{ color: "red" }} />
                                        </Grid>

                                        <Grid size={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                autoComplete="off"
                                                margin="normal"
                                                name="password"
                                                label="Password"
                                                type="password"
                                                variant="outlined"
                                                onChange={async (e) => {
                                                    await setFieldValue("password", e.target.value)
                                                    await validateField("password");
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage name="password" component="div" style={{ color: "red" }} />
                                        </Grid>


                                        <Grid size={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                autoComplete="off"
                                                margin="normal"
                                                name="confirmPassword"
                                                label="Confirm Password"
                                                type="password"
                                                variant="outlined"
                                                onChange={async (e) => {
                                                    await setFieldValue("confirmPassword", e.target.value)
                                                    await validateField("confirmPassword");
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage name="confirmPassword" component="div" style={{ color: "red" }} />
                                        </Grid>

                                        {/* <Grid size={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                autoComplete="off"
                                                disabled={values.validPhone}
                                                margin="normal"
                                                name="phoneNumber"
                                                label="Corporate Number"
                                                variant="outlined"
                                                helperText={"Make sure to use Country Code and no spaces."}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    validateField("phoneNumber")
                                                    const { phoneValidation } = validatePhoneNumber(e.target.value);
                                                    setFieldValue("country", phoneValidation.countryIso2 ? phoneValidation.countryIso2 : "");
                                                    setIsPhoneAccordionExpanded(phoneValidation.isValid);
                                                }}
                                            />
                                            <ErrorMessage name="phoneNumber" component="div" style={{ color: "red" }} />

                                        </Grid>
                                        */}
                                        
                                        <Grid size={12}>
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        as={Checkbox}
                                                        name="consentSMS"
                                                        color="primary"
                                                        onChange={async (e, value) => {
                                                            await setFieldValue("consentSMS", value)
                                                            await validateField("consentSMS");
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                }
                                                label="I agree to receive messages from RFC"
                                            />
                                            <ErrorMessage name="consentSMS" component="div" style={{ color: "red" }} />
                                        </Grid> 
                                        <PhoneNumberField
                                            values={values}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            setFieldValue={setFieldValue}
                                            validateField={validateField}
                                            setFieldError={setFieldError}
                                            setTouched={setTouched}
                                        />

                                    </Grid>

                                    <Grid container spacing={1}>
                                        {/**Only ask for Address if it is Guatemala */}
                                        {values.country === "GT" &&
                                            <>
                                                <Grid size={12}>
                                                    <Field
                                                        as={TextField}
                                                        fullWidth
                                                        disabled={!values.validPhone}
                                                        autoComplete="off"
                                                        margin="normal"
                                                        name="address"
                                                        label="Address"
                                                        variant="outlined"
                                                        helperText={!values.validPhone ?
                                                            "Please complete the One Time Password Validation."
                                                            : ""
                                                        }
                                                        onChange={async (e) => {
                                                            await setFieldValue("address", e.target.value)
                                                            await validateField("address");
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                    <ErrorMessage name="address" component="div" style={{ color: "red" }} />
                                                </Grid>
                                                <Grid size={12}>
                                                    <Field
                                                        as={TextField}
                                                        fullWidth
                                                        autoComplete="off"
                                                        margin="normal"
                                                        name="zipCode"
                                                        label="Zip Code"
                                                        variant="outlined"
                                                        onChange={async (e) => {
                                                            await setFieldValue("zipCode", e.target.value)
                                                            await validateField("zipCode");
                                                            await validateField("address");
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                    <ErrorMessage name="zipCode" component="div" style={{ color: "red" }} />
                                                </Grid>
                                            </>

                                        }
                                        <Grid size={12}>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                autoComplete="off"
                                                margin="normal"
                                                name="country"
                                                label="Country"
                                                variant="outlined"
                                                onChange={async (e) => {
                                                    await setFieldValue("country", e.target.value)
                                                    await validateField("country");
                                                }}
                                                disabled
                                            />
                                            {/* <ErrorMessage name="country" component="div" style={{ color: "red" }} /> */}
                                        </Grid>
                                    </Grid>
                                    <Accordion sx={{ mt: 2 }} expanded={isAccordionExpanded}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="dns-validation-content"
                                            id="dns-validation-header"
                                        >
                                            <Typography sx={{ mr: 1 }}>DNS Validation</Typography>
                                            {validatingDomain ? <SpinningIcon /> : values.validDomain ? <VerifiedIcon color="success" /> : <NewReleasesIcon color="warning" />}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography component={"div"} sx={{ mb: 2 }}>
                                                Add the following CNAME record to your DNS:
                                            </Typography>
                                            <Typography component={"div"}>
                                                Host: remotefreedomconnect._domainkey
                                            </Typography>
                                            <Typography component={"div"}>
                                                Type: TXT
                                            </Typography>
                                            <Typography component={"div"}
                                                sx={{
                                                    wordBreak: "break-word",
                                                    overflowWrap: "break-word",
                                                    width: "90%"  // Ensure it doesn't exceed parent width
                                                }}
                                            >
                                                Data: {domainData.hash}
                                            </Typography>

                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        as={Checkbox}
                                                        name="enteredRecord"
                                                        checked={values.enteredRecord}
                                                        color="primary"
                                                        disabled={validatingDomain}
                                                        onChange={(e) => {
                                                            handleChange(e);

                                                            if (e.target.value === false) {
                                                                return
                                                            }

                                                            const domain = values.corporateEmail.split("@")[1] || "";

                                                            const data = {
                                                                host: "remotefreedomconnect._domainkey",
                                                                domain: domain, // Assuming this is captured from form data
                                                                value: domainData.hash
                                                            };

                                                            setValidatingDomain(true);
                                                            setFieldValue("validDomain", false); // Reset validation
                                                            setIsAccordionExpanded(true);

                                                            // Call the function to validate the DNS record
                                                            validateDNSRecord(data, setFieldValue, setIsAccordionExpanded, setFieldError);
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                }
                                                label="I have added the record"
                                            />

                                            <ErrorMessage name="enteredRecord" component="div" style={{ color: "red" }} />
                                        </AccordionDetails>
                                    </Accordion>
                                    <CardActions>
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            disabled={!isValid || !dirty}
                                            loading={isSubmitting}
                                        >
                                            Register
                                        </LoadingButton>
                                    </CardActions>
                                </Form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
            </Backdrop>
        </>
    );
};

export default RegisterCompany;
