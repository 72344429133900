import { Box, Card, CardContent, Grid } from "@mui/material"
import React from "react"
import './index.scss'

export default function ServiceLayout(props) {

    return (
        <Box
            className='live-gradient'
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Card
                elevation={5}
            >

                {props.children}
            </Card>
        </Box>
    )


}