import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, FormControlLabel, Grid2 as Grid, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import { generalService } from "../../../_services/general.services";
import OTPInput from "../../_shared/elements/otp/OTP";
import { phone } from 'phone';

import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast } from "react-toastify";

const PhoneNumberField = (props) => {

    const { 
        // Formik
        values, // validPhone, phoneNumber, country
        handleChange, 
        handleBlur, 
        setFieldValue,
        setFieldError,
        validateField,  
        setTouched       
    } = props;

    const [otpData, setOtpData]                                    = React.useState({});
    const [otp, setOtp]                                            = React.useState('');
    const [countdown, setCountdown]                                = React.useState(0); // Time remaining for OTP
    const [isSendingCode, setIsSendingCode]                        = React.useState(false); // State for send button loading
    const [isPhoneAccordionExpanded, setIsPhoneAccordionExpanded]  = React.useState(false);

    // Countdown effect
    React.useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setInterval(() => setCountdown((prev) => prev - 1), 1000);
        }
        return () => clearInterval(timer);
    }, [countdown]);

    // Function to handle sending OTP code
    const handleSendCode = async (phone, country) => {
        try {
            setIsSendingCode(true);
            const response = await generalService.sendOTP({
                phone,
                country
                // Use the phone number from the form
            }); // Call API to send OTP
            const expirationTime = response.data.expirationMinutes; // Assume this is in minutes
            setOtpData(response.data); // Set the OTP value
            setCountdown(expirationTime * 60); // Convert minutes to seconds
            setIsSendingCode(false);
        } catch (err) {
            console.error("Error sending OTP:", err);
            if(err.data?.message){
                toast.error(err.data.message);
            }
            setCountdown(0);
            setOtpData({});
            setIsSendingCode(false);
        }
    };

    const handleOTPComplete = async (setFieldValue, otp) => {
        try {

            setIsSendingCode(true);
            const response = await generalService.verifyOTP({
                code: otp,
                id: otpData.id
            }); // Call API to send OTP

            if (response?.status === 200) {
                console.log("OTP verified successfully");
                setFieldValue("validPhone", true);
                setFieldError("validPhone", undefined);
                setIsPhoneAccordionExpanded(false);
            }

            setIsSendingCode(false);
            // Handle OTP submission here
        } catch (err) {
            setFieldValue("validPhone", false);
            setFieldError("validPhone", "OTP not valid.");

            if(err.data?.message){
                toast.error(err.data.message);
            }
            setCountdown(0);
            setOtpData({});
            setIsSendingCode(false);
        }
    };

    const validatePhoneNumber = (e) => {

        let noError = true;
        const phoneValidation = phone(e);
        if (!phoneValidation.isValid) {
            noError = 'Invalid phone number, make sure to add a "+", country code, and a number without spaces.'
            setFieldError("phoneNumber", noError);
        }else if(phoneValidation.isValid){
            setFieldError("phoneNumber", undefined);
        }

        return { noError, phoneValidation };
    };

    return (
        <Grid
            container
        >
            <Grid size={12}>
                <Field
                    as={TextField}
                    fullWidth
                    autoComplete="off"
                    disabled={typeof values?.validPhone === "string" ? values?.validPhone.toLowerCase() === "true" : values?.validPhone}
                    margin="normal"
                    name="phoneNumber"
                    label="Corporate Number"
                    variant="outlined"
                    helperText={"Make sure to use Country Code and no spaces."}
                    onChange={(e) => {
                        handleChange(e);
                        const { phoneValidation } = validatePhoneNumber(e.target.value);
                        setFieldValue("country", phoneValidation.countryIso2 ? phoneValidation.countryIso2 : "");
                        setIsPhoneAccordionExpanded(phoneValidation.isValid);
                    }}
                />
                <ErrorMessage name="phoneNumber" component="div" style={{ color: "red" }} />

            </Grid>

            {/* <Grid size={12}>
                <FormControlLabel
                    control={
                        <Field
                            as={Checkbox}
                            name="consentSMS"
                            color="primary"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    }
                    label="I agree to receive messages from RFC"
                />
                <ErrorMessage name="consentSMS" component="div" style={{ color: "red" }} />
            </Grid> */}

            {/**Phone Number validation process */}
            <Grid size={12}>
                < Accordion sx={{ mt: 2 }} expanded={isPhoneAccordionExpanded} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="phone-validation-content"
                        id="phone-validation-header"
                    >
                        <Typography sx={{ mr: 1 }}>One Time Password Validation</Typography>
                        {values?.validPhone ? <VerifiedIcon color="success" /> : <NewReleasesIcon color="warning" />}
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* Send Code Button */}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSendCode(values?.phoneNumber, values?.country)}
                            disabled={isSendingCode || countdown > 0}
                            sx={{ mb: 2 }}
                        >
                            {isSendingCode ? (
                                <CircularProgress size={24} />
                            ) : countdown > 0 ? (
                                `Resend in ${Math.floor(countdown / 60)}:${(countdown % 60).toString().padStart(2, '0')}`
                            ) : (
                                "Send Code"
                            )}
                        </Button>
                        <Typography component={"div"} sx={{ mb: 2 }}>
                            Please validate your Phone:
                        </Typography>
                        <OTPInput otp={otp} setOtp={setOtp} handleComplete={(e) => handleOTPComplete(setFieldValue, e)} />
                        <ErrorMessage name="validPhone" component="div" style={{ color: "red" }} />
                    </AccordionDetails>
                </Accordion >
                {/**Phone Number validation process END */}
            </Grid>
        </Grid>
    )
}

export default PhoneNumberField